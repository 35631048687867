import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

function isDev() {
  return (
    (typeof window !== "undefined" &&
      window.location.hostname === "localhost") ||
    process.env.NODE_ENV !== "production"
  );
}

module.exports = {
  initializeSentry: () => {
    if (isDev()) return;
    Sentry.init({
      dsn:
        "https://6a49f3637a194d0b88160ed528f1c82e@o925052.ingest.sentry.io/4505360033710080",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.25,
    });
  },
};
